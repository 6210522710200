body {
  @apply text-gray-900;
}

@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: optional;
  font-style: oblique 0deg 10deg;
  src: url("/fonts/Inter.var.woff2?v=3.15") format("woff2");
}
